<template>
  <div id="card">
    <div class="logo">
      <img src="../assets/image/b.png" alt="" />
      <div class="title">个人信息</div>
    </div>
    <div class="info">
      <van-panel>
        <div slot="header" class="headinfo">
          <p>姓名：{{ peoinfo.name }}</p>
          <p>性别：{{ peoinfo.sex == 0 ? "男" : "女" }}</p>
          <p>学号：{{ peoinfo.stunum }}</p>
          <p>生效日期：{{ peoinfo.effectiveTime }}</p>
          <p>失效日期：{{ peoinfo.expireTime }}</p>
        </div>
      </van-panel>
    </div>
  </div>
</template>
<script>
import { formatTime } from "@/utils/index.js";
import { getCard } from "@/api/goods.js";
export default {
  data() {
    return {
      peoinfo: {
        name: "",
        sex: "",
        stunum: "",
        expireTime: "",
        effectiveTime: "",
      },
    };
  },
  created() {
    getCard().then((res) => {
      this.peoinfo.name = res.data.data.name;
      this.peoinfo.sex = res.data.data.sex;
      this.peoinfo.stunum = res.data.data.stunum;
      this.peoinfo.effectiveTime = formatTime(
        res.data.data.effectiveTime,
        "yyyy-MM-dd"
      );
      this.peoinfo.expireTime = formatTime(
        res.data.data.expireTime,
        "yyyy-MM-dd"
      );

      this.peoinfo = { ...this.peoinfo };
    });
  },
};
</script>
<style lang="scss" scoped>
#card {
  width: 100%;
  height: 100%;
  .logo {
    margin-top: 50px;
    width: 100%;
    height: 200px;
    img {
      width: 120px;
    }
    .title {
      text-align: center;
      width: 100%;
      font-size: 20px;
    }
  }
  .info {
    .van-panel {
      font-size: 16px;
      width: 90%;
      height: 250px;
      margin: auto;
      border-radius: 10px;
      background-image: url(../static/image/card2.png);
      background-size: cover;
      .headinfo {
        padding: 22px 40px;
        text-align: left;
        box-sizing: border-box;
        p {
          line-height: 36px;
          margin-top: 10px;
          font-size: 20px;
        }
      }
    }
  }
}
</style>